/* eslint-disable import/no-unresolved */
/// Portal


import * as THREE from 'three';
import * as THREEAR from 'threear';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
const tree = new URL('../assets/tree.glb', import.meta.url).href;
const lixi1 = new URL('../assets/lixi_1.glb', import.meta.url).href;
const lixi2 = new URL('../assets/lixi_2.glb', import.meta.url).href;
const lixi3 = new URL('../assets/lixi_3.glb', import.meta.url).href;
const imageTracking = new URL('../assets/vnpaylogo.patt', import.meta.url).href;
const imageLogo = new URL('../assets/vnpaylogo.png', import.meta.url).href;

import './index.css';

var camera = new THREE.PerspectiveCamera();
const renderer = new THREE.WebGLRenderer(
  { antialias: true, alpha: true },
);
renderer.localClippingEnabled = true;
renderer.shadowMap.enabled = true;
renderer.domElement.style.position = 'absolute'
renderer.domElement.style.top = '0px'
renderer.domElement.style.left = '0px'
document.body.appendChild(renderer.domElement);

// As with a normal ThreeJS scene, resize the canvas if the window resizes
renderer.setSize(window.innerWidth, window.innerHeight);
window.addEventListener('resize', () => {
  renderer.setSize(window.innerWidth, window.innerHeight);
});

var source = new THREEAR.Source({ renderer, camera});

THREEAR.initialize({ source: source }).then((controller) => {

  var patternMarker = new THREEAR.PatternMarker({
    patternUrl: imageTracking,
    markerObject: portal
  });
  controller.trackMarker(patternMarker);
  // run the rendering loop
  var lastTimeMsec = 0;
  requestAnimationFrame(function animate(nowMsec){
    // keep looping
    requestAnimationFrame( animate );
    // measure time
    lastTimeMsec = lastTimeMsec || nowMsec-1000/60;
    var deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
    lastTimeMsec = nowMsec;
    // call each update function
    controller.update( source.domElement );
    
    renderer.render(scenePortal, camera);
    if (modelTree) {
      modelTree.rotation.y += 0.01;
    }
  });

});
// Create our portal and portal mask scenes
const scenePortal = new THREE.Scene();


// Create portal group
const portal = new THREE.Group();
let modelTree: THREE.Object3D;
scenePortal.add(portal)
portal.visible = true;

var pickableObjects: THREE.Object3D[] = []

const loader = new GLTFLoader();
loader.load( tree, function ( gltf ) {

		const model = gltf.scene;
    model.position.set(0, 0, 0)
    model.rotation.z = 0
    model.rotation.x = 5
    model.rotation.y = 0


		portal.add( model );
    modelTree = model
    // var lightP = new THREE.PointLight( 0xffffff, 1 );
    // modelTree.add(lightP)

    var directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.target = modelTree;
    modelTree.add(directionalLight);
    loadLixi()

});

function loadLixi() {
  loader.load( lixi1, function ( gltf ) {

    const model = gltf.scene;
    model.position.set(-1.2, 0.9, 0)
  
    modelTree.add( model );
    pickableObjects.push(model)
  
  });
  loader.load( lixi2, function ( gltf ) {

  
    const model = gltf.scene;
    model.position.set(-3, 0.9, 0)
  
    modelTree.add( model );
    pickableObjects.push(model)
  
  });
  loader.load( lixi3, function ( gltf ) {
  
    const model = gltf.scene;
    model.position.set(-2, 0.9, 0)
  
    modelTree.add( model );
    pickableObjects.push(model)
  
  });
}

renderer.domElement.addEventListener( 'click', raycast, false );

var raycaster: THREE.Raycaster, mouse = { x : 0, y : 0 };
raycaster = new THREE.Raycaster();
function raycast ( e: any ) {
  console.log('click====>')

  //1. sets the mouse position with a coordinate system where the center
  //   of the screen is the origin
  mouse.x = ( e.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( e.clientY / window.innerHeight ) * 2 + 1;

  //2. set the picking ray from the camera position and mouse coordinates
  raycaster.setFromCamera( mouse, camera );    

  var intersects = raycaster.intersectObjects( pickableObjects);

  var pickableObject: THREE.Object3D| null = null
  for ( var i = 0; i < pickableObjects.length; i++ ) {
   
    for ( var j = 0; j < intersects.length; j++ ) {
      var check: boolean = checkObject( pickableObjects[i], intersects[j])
      if (check) {
        pickableObject = pickableObjects[i]
      }
    }
  }
  if (pickableObject) {
    pickableObjects = pickableObjects.filter(item => item.uuid !== pickableObject?.uuid);
    modelTree.remove(pickableObject)
  }
}

function checkObject(object:THREE.Object3D, intersect: THREE.Intersection): boolean {
  for (var i = 0; i < object.children.length; i++) {
    var mesh = object.children[i]
    if (mesh.name == intersect.object.name) {
      return true
    }
    if (mesh.children.length > 0) {
      let check: boolean = checkObject(mesh, intersect)
      if (check) return check
    } 
  }
  return false
}

// // Add some lighting to show off the depth
// const directionalLight = new THREE.DirectionalLight(0xfaccb2, 1);
// directionalLight.intensity = 1.5;
// directionalLight.castShadow = true;
// directionalLight.position.set(0, 10, 0);
// directionalLight.target.position.set(-5, 0, 0);
// portal.add(directionalLight);
// portal.add(directionalLight.target);

var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
directionalLight.target = portal;
portal.add(directionalLight);

var light = new THREE.AmbientLight(0x404040);
scenePortal.add(light);

